html, body, button, input, select, textarea {
  font: 14px "Helvetica Neue", Helvetica, Arial, sans-serif;
  line-height: 18px;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

html, body {
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
  background-color: #fafafa;
}

#root {
  width: 100%;
  height: 100%;
}
